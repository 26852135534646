<template>
  <div class="mt-5">
    <px-loading-horizontal-cards :loading="loading"/>
    <v-row v-if="categorias.length">
      <v-col cols="12">
        <v-slide-group
          :class="sliderClass"
          v-model="slideState"
          active-class="none"
          show-arrows
        >
          <v-slide-item
            v-for="(categoria) in categorias"
            :key="categoria.id"
          >
            <px-categoria-card :categoria="categoria"/>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CategoriaService from '@/api/categoria-service';
import FileUrlPathService from '@/api/file-url-path-service';
import gridMixin from '@/mixins/grid-mixin';
import CategoriaCard from './CategoriaCard.vue';
import defaultPhoto from '../../assets/default-categoria.png';

export default {
  mixins: [gridMixin],
  props: {
    query: {
      type: String,
      default: 'ativo is true and fixo is true order by random',
    },
  },
  data() {
    return {
      loading: false,
      slideState: null,
      categorias: [],
    };
  },
  computed: {
    sliderClass() {
      if (this.isMobile) {
        return 'pt-3 pb-2 px-slider';
      }
      return 'pt-3 pb-2 px-slider slider-categoria-left slider-categoria-right';
    },
  },
  methods: {
    mapCategorias(categoria) {
      if (!categoria.arquivo) {
        return { ...categoria, foto: defaultPhoto };
      }
      return { ...categoria, foto: FileUrlPathService.getPath(categoria.arquivo.path) };
    },
    getCategorias() {
      this.loading = true;
      CategoriaService.getCategorias({
        limit: 50,
        query: this.query,
      })
        .then(({ data }) => {
          this.categorias = data.map(this.mapCategorias);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCategorias();
  },
  components: {
    pxCategoriaCard: CategoriaCard,
  },
};
</script>
