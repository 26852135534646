<template>
  <v-card :max-width="width"
          :min-width="width"
          :min-height="height"
          :max-height="height"
          class="mr-4 categoria-card"
          @click="onClickCategoria(categoria)"
          @mousedown.middle="onClickCategoria(categoria, true)">
    <v-img
      :height="height"
      :gradient="imageGradient"
      :src="categoria.foto"
    >
      <v-card-text
        class="d-flex align-end justify-start fill-height">
        <span :class="`white--text ${textClass} font-weight-bold`">
          {{ categoria.descricao }}
        </span>
      </v-card-text>
    </v-img>
  </v-card>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import { goToOfertasCategoria } from '../../services/route-oferta-service';

export default {
  mixins: [gridMixin],
  props: {
    categoria: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageGradient: this.$pxMarketplace.DEFAULT_IMAGE_GRADIENT,
      defaultIcon: 'mdi-hammer-wrench',
    };
  },
  computed: {
    textClass() {
      if (this.isMobile) {
        return 'body-1';
      }
      return 'title';
    },
    height() {
      if (this.isMobile) {
        return '157px';
      }
      return '280px';
    },
    width() {
      if (this.isMobile) {
        return '157px';
      }
      return '280px';
    },
  },
  methods: {
    onClickCategoria(categoria, newTab) {
      goToOfertasCategoria(categoria.id, newTab);
    },
  },
};
</script>

<style scoped lang="scss">
@import './src/scss/variables';

.categoria-card {
  border-radius: $default-border-radius !important;
}
</style>
