<template>
  <div>
    <v-carousel
      cycle
      :height="height"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item
        class="banner-redirect"
        v-for="(item,i) in banners"
        :key="i"
        :src="item.foto"
        link
        :href="item.link"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import FileUrlPathService from '@/api/file-url-path-service';
import BannerService from './banner-service';

export default {
  mixins: [gridMixin],
  data() {
    return {
      loading: false,
      banners: [],
    };
  },
  computed: {
    height() {
      if (this.isMobile) {
        return '360';
      }
      return '320';
    },
  },
  methods: {
    buildFoto(banner) {
      if (this.isMobile && banner.arquivoMobile) {
        return FileUrlPathService.getPath(banner.arquivoMobile.path);
      }
      return FileUrlPathService.getPath(banner.arquivo.path);
    },
    mapBanner(banner) {
      return { ...banner, foto: this.buildFoto(banner) };
    },
    getBanners() {
      this.loading = true;
      BannerService.getBanners({
        offset: 0,
        limit: 80,
        query: 'tipoBanner = "PRINCIPAL" order by ordenacao',
      })
        .then(({ data }) => {
          this.banners = data.content.map(this.mapBanner);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getBanners();
  },
};
</script>

<style scoped>
.banner-redirect {
  cursor: pointer;
}
</style>
