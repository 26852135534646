<template>
  <v-row dense no-gutters>
    <v-col cols="6" class="mt-3"
           v-for="(banner, index) in banners" :key="index">
      <px-home-banner-card :title="banner.titulo"
                           :link="banner.link"
                           :banner-image="banner.foto"
                           gradient="none"
                           :max-height="height"
                           :min-height="height"/>
    </v-col>
  </v-row>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import HomeBannerCard from '@/modules/home-page/HomeBannerCard.vue';
import FileUrlPathService from '@/api/file-url-path-service';
import BannerService from '@/modules/home-page/banner-service';

export default {
  mixins: [gridMixin],
  data() {
    return {
      banners: [],
    };
  },
  computed: {
    height() {
      if (this.isMobile) {
        return '180px';
      }
      return '360px';
    },
  },
  methods: {
    buildFoto(banner) {
      if (this.isMobile && banner.arquivoMobile) {
        return FileUrlPathService.getPath(banner.arquivoMobile.path);
      }
      return FileUrlPathService.getPath(banner.arquivo.path);
    },
    mapBanner(banner) {
      return { ...banner, foto: this.buildFoto(banner) };
    },
    getBanners() {
      this.loading = true;
      BannerService.getBanners({
        offset: 0,
        limit: 80,
        query: 'tipoBanner = "FAIXA_DUPLA" order by ordenacao',
      })
        .then(({ data }) => {
          this.banners = data.content.map(this.mapBanner);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getBanners();
  },
  components: {
    pxHomeBannerCard: HomeBannerCard,
  },
};
</script>
